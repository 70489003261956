.hero-section span {
    font-size: 20px;
}

.hero-section p {
    color: white;
}

#video-container {
    top:0%;
    left:0%;
    height:100%;
    width:100%;
    overflow: hidden;
        position: absolute;
}
video {
    position:absolute;
    z-index:-2;
        width: 100%;
}

#video-container .overlay {
    background: black;
    opacity: 0.5;
    position: absolute;
    z-index: -1;
    text-align: center;
    width: 100%;
    height: 100%;
    margin: 0%;
  }

.hero-section {
    /* height: 100vh; */
    background: url(https://metawaifus.com/img/waifu/waifus_gallery_hd.webm);
    /* background-position: 50% !important; */
    /* background-repeat: no-repeat !important; */
    background-size: cover !important;
    background-attachment: fixed !important;
    background-color: #222430 !important;
    box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 35%);
    /* z-index: -2; */
    /* position: fixed; */
    /* width: 100%; */
    height: 100vh;
}

.waifu-section-1 {
    /* height: 100vh; */
    background: url(https://metawaifus.com/img/waifu/eva2.png);
    /* background-position: 50% !important; */
    /* background-repeat: no-repeat !important; */
    background-size: cover !important;
    background-attachment: fixed !important;
    background-color: #222430 !important;
    box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 60%);
 
    /* width: 100%; */
    /* height: 100vh; */
    height: 75vh;

}

.waifu-section-2 {
    /* height: 100vh; */
    background: url(https://metawaifus.com/img/waifu/metawaifus-art.jpg);
    /* background-position: 50% !important; */
    /* background-repeat: no-repeat !important; */
    background-size: cover !important;
    background-attachment: fixed !important;
    background-color: #222430 !important;
    box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 60%);
 
    /* width: 100%; */
    /* height: 100vh; */
    height: 75vh;

}

.waifu-section-3 {
    /* height: 100vh; */
    background: url(https://metawaifus.com/img/waifu/waifu_lab.png);
    /* background-position: 50% !important; */
    /* background-repeat: no-repeat !important; */
    background-size: cover !important;
    background-attachment: fixed !important;
    background-color: #222430 !important;
    box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 60%);
 
    /* width: 100%; */
    /* height: 100vh; */
    height: 75vh;

}

.waifu-section-4 {
    /* height: 100vh; */
    background: url(https://metawaifus.com/img/waifu/waifu_lab2.png);
    /* background-position: 50% !important; */
    /* background-repeat: no-repeat !important; */
    background-size: cover !important;
    background-attachment: fixed !important;
    background-color: #222430 !important;
    box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 60%);
 
    /* width: 100%; */
    /* height: 100vh; */
    height: 75vh;

}

.waifu-section-text-container1 {
    padding-top: 200px !important;
    padding-bottom: 288px !important;
}

.waifu-section-text-container2 {
    padding-top: 250px !important;
    padding-bottom: 288px !important;
}

.waifu-section-title-separator {
    height: 10px;
    background: #c800f1;
    border-color: #c800f1;
    width: 271px;
    border-bottom-width: 7px;
    margin: 42px 0 34px;
    text-align: inherit;
}

.waifu-section-text {
    color: white;
    font-size: 1.2rem;
    font-weight: 300;
}

.breadcrumb-area {
    background: url(https://metawaifus.com/img/waifu/eva3-1.png);
}

.hero-section span {
    background-image: linear-gradient(150deg, #ffffff 0%, #ffffff 78%);
}

.roadmap-text {
    color: white;
}

b {
    color: #c800f1;
}

.card-body {
    color: white;
}

@media only screen and (max-width: 550px) {
    .waifu-section-1, .waifu-section-2 {        
        height: 100% !important;
    }
  }